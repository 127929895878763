<template>
<div style="margin-left: auto;">
    <div class="main-navigation mobile-menu-control-wrapper" id="mobile-menu-control-wrapper">
        <button class="menu-toggle" v-on:click="toggleMenu()">
            <span class="gp-icon icon-menu-bars">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
                    <path d="M0 96c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24z"/>
                </svg>
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
                    <path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z"/>
                </svg>
            </span>
            <span class="screen-reader-text">Menu</span>
        </button>
    </div>
    <div id="generate-slideout-menu" class="main-navigation slideout-navigation do-overlay offside offside--left" v-bind:class="{'is-open' : mobileMenu}">
        <div class="inside-navigation grid-container grid-parent">
            <button class="slideout-exit has-svg-icon" v-on:click="toggleMenu()">
                <span class="gp-icon pro-close">
                    <svg viewBox="0 0 512 512" role="img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1em" height="1em">
                        <path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z" />
                    </svg>
                </span>
                <span class="screen-reader-text">Close</span>
            </button>
            <div class="main-nav">
                <ul class="slideout-menu">
                    <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Home'}">
                        <router-link to="/" @click.native="closeMenu">Home</router-link>
                    </li>
                    <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Chi sono'}">
                        <router-link to="chisono" @click.native="closeMenu">Chi sono</router-link>
                    </li>
                    <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Servizi'}">
                        <router-link to="servizi" @click.native="closeMenu">Servizi</router-link>
                    </li>
                    <li class="menu-item">
                        <a target="_blank" href="https://www.ilblogdeldiritto.it/blog/" @click.native="closeMenu">Blog</a>
                    </li>
                    <li class="nav-button menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Contatti'}">
                        <router-link to="contatti" @click.native="closeMenu">Contatti</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: 'MenuMobile',
        data() {
            return {
                mobileMenu: false
            }
        },
        methods: {
            closeMenu() {
                this.mobileMenu = false;
            },
            toggleMenu() {
                this.mobileMenu ? this.mobileMenu = false : this.mobileMenu = true;
            }
        }
    }
</script>

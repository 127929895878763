<template>
    <div class="site-footer">
        <div class="gb-container gb-container-8ea0a712 footer-background">
            <div class="gb-inside-container">
                <div class="gb-grid-wrapper gb-grid-wrapper-625e6116">
                    <div class="gb-grid-column gb-grid-column-08e508e0">
                        <div class="gb-container gb-container-08e508e0">
                            <div class="gb-inside-container">
                                <h3 class="gb-headline gb-headline-622fc3e1 gb-headline-text">Fissa un appuntamento per una consulenza.</h3>
                                <p class="gb-headline gb-headline-cb7c3c96 gb-headline-text">
                                    Lo studio è aperto dal lunedì al venerdì dalle ore 9 alle ore 13 e dalle ore 16 alle 19. Il notaio è disponibile a ricevere su richiesta anche al di fuori degli orari di studio.
                                </p>
                                <div class="gb-button-wrapper gb-button-wrapper-bf52e756">
                                    <router-link class="gb-button gb-button-a9c9002d gb-button-text" to="contatti">Contatta lo studio</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gb-grid-column gb-grid-column-c67101ac">
                        <div class="gb-container gb-container-c67101ac">
                            <div class="gb-inside-container">
                                <p class="gb-headline gb-headline-0ba1c93f gb-headline-text">
                                    Studio Notarile TORRELLI<br>
                                    Corso Vittorio Emanuele 23<br>
                                    67100 L'Aquila (AQ)
                                </p>
                                <p class="gb-headline gb-headline-5be1105d">
                                    <span class="gb-icon">
                                    <svg viewBox="0 0 43.3 59.3" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M35 59.3H8.3C3.7 59.3 0 55.6 0 51V8.3C0 3.7 3.7 0 8.3 0H35c4.6 0 8.3 3.7 8.3 8.3V51c0 4.6-3.7 8.3-8.3 8.3zM8.3 6C7 6 6 7 6 8.3V51c0 1.3 1 2.3 2.3 2.3H35c1.3 0 2.3-1 2.3-2.3V8.3C37.3 7 36.3 6 35 6H8.3z"></path>
                                        <path d="M21.7 48.7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path>
                                    </svg>
                                    </span>
                                    <span class="gb-headline-text"><a href="mailto:gtorrelli@notariato.it">gtorrelli@notariato.it</a></span>
                                </p>
                                <p class="gb-headline gb-headline-5be1105d">
                                    <span class="gb-icon">
                                    <svg viewBox="0 0 43.3 59.3" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M35 59.3H8.3C3.7 59.3 0 55.6 0 51V8.3C0 3.7 3.7 0 8.3 0H35c4.6 0 8.3 3.7 8.3 8.3V51c0 4.6-3.7 8.3-8.3 8.3zM8.3 6C7 6 6 7 6 8.3V51c0 1.3 1 2.3 2.3 2.3H35c1.3 0 2.3-1 2.3-2.3V8.3C37.3 7 36.3 6 35 6H8.3z"></path>
                                        <path d="M21.7 48.7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path>
                                    </svg>
                                    </span>
                                    <span class="gb-headline-text">351 6218378</span>
                                </p>
                                <p class="gb-headline gb-headline-5be1105d">
                                    <span class="gb-icon">
                                    <svg viewBox="0 0 43.3 59.3" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M35 59.3H8.3C3.7 59.3 0 55.6 0 51V8.3C0 3.7 3.7 0 8.3 0H35c4.6 0 8.3 3.7 8.3 8.3V51c0 4.6-3.7 8.3-8.3 8.3zM8.3 6C7 6 6 7 6 8.3V51c0 1.3 1 2.3 2.3 2.3H35c1.3 0 2.3-1 2.3-2.3V8.3C37.3 7 36.3 6 35 6H8.3z"></path>
                                        <path d="M21.7 48.7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path>
                                    </svg>
                                    </span>
                                    <span class="gb-headline-text">0862 1720024</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gb-container gb-container-30166353">
                    <div class="gb-inside-container">
                    <div class="gb-grid-wrapper gb-grid-wrapper-150dce3f">
                        <div class="gb-grid-column gb-grid-column-bd73b5c4">
                            <div class="gb-container gb-container-bd73b5c4">
                                <div class="gb-inside-container">
                                <p class="gb-headline gb-headline-a8a1fab4 gb-headline-text">© Studio Notarile TORRELLI</p>
                                </div>
                            </div>
                        </div>
                        <div class="gb-grid-column gb-grid-column-571e035c">
                            <div class="gb-container gb-container-571e035c">
                                <div class="gb-inside-container">
                                    <div class="gb-button-wrapper gb-button-wrapper-5d2efd4d">
                                        <a target="_blank" class="gb-button gb-button-acc1ae52 gb-button-text" href="https://www.iubenda.com/privacy-policy/92737426">Privacy policy</a>
                                        <!--<a class="gb-button gb-button-acc1ae52 gb-button-text" href="#">Privacy Policy</a>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="gb-shapes">
                <div class="gb-shape gb-shape-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                    <path d="M1200 100H0V0l400 77.2L1200 0z"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<template>
  <div>
    <Spinner/>
    <Header/>
    <div class="site grid-container container hfeed" id="page">
        <div class="site-content" id="content">
            <div class="content-area" id="primary">
                <main class="site-main" id="main">
                    <router-view/>
                </main>
            </div>
        </div>
        <Footer/>
        <ScrollTop/>
    </div> 
  </div>
</template>
<script>
// @ is an alias to /src
import ScrollTop from '@/components/ScrollTop'
import Spinner from '@/components/Spinner'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
    components: {
        Spinner,
        Header,
        Footer,
        ScrollTop
    }
}
</script>
<template>
    <form>
        <div class="row">
            <div class="alert alert-danger" v-for="(error,index) in errors" :key="index">
              {{ error }}
            </div>
            <div v-if="message" class="alert alert-success">
              {{ message }}
            </div>
            <div class="col-md-6">
                <div class="single-input">
                    <input v-model="contact.name" type="text" id="name" name="name" class="form-input" placeholder="Nome">
                    <i class="lni lni-user"></i>
                </div>
            </div>
            <div class="col-md-6">
                <div class="single-input">
                    <input v-model="contact.email" type="email" id="email" name="email" class="form-input" placeholder="Email">
                    <i class="lni lni-envelope"></i>
                </div>
            </div>
            <div class="col-md-6">
                <div class="single-input">
                    <input v-model="contact.phone" type="text" id="number" name="phone" class="form-input" placeholder="Telefono">
                    <i class="lni lni-phone"></i>
                </div>
            </div>
            <div class="col-md-6">
                <div class="single-input">
                    <select class="form-input input100 select-input" v-model="contact.subject">
                        <option :value="null" disabled selected="selected">Seleziona un oggetto</option>
                        <option value="Vendita">Vendita</option>
                        <option value="Donazione">Donazione</option>
                        <option value="Divisione">Divisione</option>
                        <option value="Procura">Procura</option>
                        <option value="Testamento">Testamento</option>
                        <option value="Atto societario">Atto societario</option>
                        <option value="Altro">Altro</option>
                    </select>
                    <!--<input v-model="contact.subject" type="text" id="subject" name="subject" class="form-input" placeholder="Oggetto">
                    <i class="lni lni-text-format"></i>-->
                </div>
            </div>
            <div class="col-md-12">
                <div class="single-input">
                    <textarea v-model="contact.message" name="message" id="message" class="form-input" placeholder="Messaggio" rows="6"></textarea>
                    <i class="lni lni-comments-alt"></i>
                </div>
            </div>
            <div class="col-md-12">
              <div class="single-input">
                <div class="form-check">
                  <input v-model="contact.privacy" class="form-check-input" type="checkbox" value="on" id="privacy">
                  <label class="form-check-label" for="privacy">
                    Accetto i termini della <a target="_blank" href="https://www.iubenda.com/privacy-policy/92737426">privacy policy</a>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-12">
                <div class="form-button">
                    <button v-on:click="sendMail()" type="button" class="button"><i class="lni lni-telegram-original"></i>
                        Invia
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: 'FormContact',
        data() {
            return {
                contact: {
                    name: null,
                    email: null,
                    phone: null,
                    subject: null,
                    message: null,
                    privacy: null
                },
                errors: null,
                message: null
            }
        },
        methods: {
            sendMail() {
                this.errors = [];
                if (!this.contact.name || !this.contact.phone || !this.contact.email || !this.contact.subject || !this.contact.message || !this.contact.privacy) {
                  this.errors.push('Attenzione: ci sono campi vuoti oppure devi accettare la privacy policy.');
                }else{
                    this.$store.commit('showSpinner');
                    this.$http.post('https://test.notaiotorrelli.it/mail/mail.php', this.contact)
                    .then((result) => {
                        this.$store.commit('hideSpinner');
                        this.message = result.data.message;
                    }).catch(error => {
                        this.$store.commit('hideSpinner');
                        this.errors.push(error.response.data.error);
                        console.error("Error:", error.message);
                    });
                }
            }
        }
    }
</script>
<style scoped>
    .single-input{
        margin-bottom: 10px;
    }

    .single-input input{
        width: 100%;
    }

    .alert{
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .alert-danger{
      background-color: #f8d7da;
      color: #721c24;
    }

    .alert-success{
      background-color: #d4edda;
      color: #155724;
    }

    .single-input a {
      color: #b07b7f !important;
    }

    .form-check-input{
      width: auto !important;
    }

    .input100{
      width: 100%;
    }

    .select-input{
      padding: 10px 12px;
      color: #7f7f7f;
    }
</style>
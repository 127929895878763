<template>
    <div class="scroll-top" v-on:click="scrollToTop" v-if="scrolled"> <font-awesome-icon icon="angle-up" size="lg" /> </div>
</template>
<script>
    export default {
        name: 'ScrollTop',
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        data() {
            return {
                scrolled: false
            }
        },
        methods: {
            handleScroll() {
                this.scrolled = window.scrollY > 50;
            },
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    }
</script>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    spinner: true
  },
  getters: {
    getStatusSpinner: state => {
      return state.spinner;
    }
  },
  mutations: {
    showSpinner(state){
      state.spinner = true;
    },
    hideSpinner(state){
      state.spinner = false;
    }
  },
  actions: {},
  modules: {}
});

<template>
<div>
    <div class="header-wrap">
        <a class="screen-reader-text skip-link" href="#content" title="Skip to content">Skip to content</a>
        <header class="site-header has-inline-mobile-toggle">
            <div class="inside-header grid-container">
                <div class="site-branding">
                    <p class="main-title"><a href="/" rel="home"><img width="100" src="assets/img/logotorrelli.png"> </a>
                    </p>
                </div>
                <MenuMobile/>
                <nav class="main-navigation sub-menu-right" id="site-navigation">
                    <div class="inside-navigation grid-container">
                        <button class="menu-toggle" aria-controls="primary-menu" aria-expanded="false">
                            <span class="gp-icon icon-menu-bars">
                                <svg viewBox="0 0 512 512" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="1em"
                                    height="1em">
                                <path d="M0 96c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24z"/>
                                </svg>
                                <svg viewBox="0 0 512 512" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="1em"
                                    height="1em">
                                <path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z"/>
                                </svg>
                            </span>
                            <span class="screen-reader-text">Menu</span>
                        </button>
                        <div id="primary-menu" class="main-nav">
                            <ul id="menu-menu" class=" menu sf-menu">
                                <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Home'}">
                                    <router-link to="/" @click.native="changeMenu('home')">Home</router-link>
                                </li>
                                <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Chi sono'}">
                                    <router-link to="chisono">Chi sono</router-link>
                                </li>
                                <li class="menu-item" v-bind:class="{'current-menu-item' : this.$route.name === 'Servizi'}">
                                    <router-link to="servizi">Servizi</router-link>
                                </li>
                                <li class="menu-item">
                                    <a target="_blank" href="https://www.ilblogdeldiritto.it/blog/">Blog</a>
                                </li>
                                <li class="nav-button" v-bind:class="{'current-menu-item' : this.$route.name === 'Contatti'}">
                                    <router-link to="contatti">Contatta lo studio</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    </div>
    <div class="gb-container gb-container-fb3a3e68" v-if="!isHome">
        <div class="gb-inside-container">
            <h1 class="gb-headline gb-headline-95186c53 gb-headline-text gb-headline-page">{{this.$route.name}}</h1>
        </div>
        <div class="gb-shapes">
            <div class="gb-shape gb-shape-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                    <path d="M1200 100H0V0l400 77.2L1200 0z"/>
                </svg>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import MenuMobile from '@/components/MenuMobile'
    export default {
        name: 'Header',
        data() {
            return {}
        },
        methods: {
            
        },
        computed: {
            isHome() {
                return this.$route.name === 'Home'
            }
        },
        components: {
            MenuMobile
        }
    }
</script>
